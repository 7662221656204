import React, {useEffect, useState} from 'react';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import PricingPackeges from '../../components/pricing/pricingPackeges';
import PremiumCredits from '../../components/pricing/premium-credits';
import Switch from '../../components/switch';

import {useQuery} from '@apollo/react-hooks';
import {GET_HEXOMATIC_USER} from '../../graphql/queries';
import './styles.scss';
import TrustedSection from '../../components/trusted-section';
import {twoMonthsFreeIcon} from '../../images/svgIcons';

const Pricing = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(true);
  const ltd = false;
  const [promo, setPromo] = useState(false);
  const [showLtdText, setShowLtdText] = useState(false);
  const [leftSeats, setLeftSeats] = useState(0);
  const [annualPromo, setAnnualPromo] = useState(false);

  // const {data: flashPromo, loading: flashPromoLoading} = useQuery(GET_FLASH_PROMO_DATA, {
  //   fetchPolicy: 'no-cache',
  // });
  const {data: hexomaticUserInfo, refetch} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const packageHexomatic =
      hexomaticUserInfo &&
      hexomaticUserInfo.HexomaticUser &&
      hexomaticUserInfo.HexomaticUser.getByLegacyId &&
      hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package;
    const packageHexomaticInterval =
      hexomaticUserInfo &&
      hexomaticUserInfo.HexomaticUser &&
      hexomaticUserInfo.HexomaticUser.getByLegacyId &&
      hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package_interval;
    const promoData =
      hexomaticUserInfo &&
      hexomaticUserInfo.User &&
      hexomaticUserInfo.User.get &&
      hexomaticUserInfo.User.get.promoData &&
      hexomaticUserInfo.User.get.promoData.hexomatic_ltd;
    if (promoData && packageHexomatic && (packageHexomatic === 'FREE' || packageHexomaticInterval === 'LTD')) {
      // setShowLtdText(true);
      // setAnnual(true);
    } else if (!promoData && (packageHexomaticInterval === 'ANNUAL' || packageHexomatic === 'GOLD')) {
      setAnnual(true);
    } else {
      setShowLtdText(false);
    }
  }, [hexomaticUserInfo]);

  return (
    <Layout pricingPage={showLtdText} fusebasePromo>
      <PageHero
        title="Hexomatic Pricing Plans"
        subtitleEl={
          showLtdText ? (
            <>
              <p className="m-0">
                <strong style={{fontWeight: 700}}>FLASH LIFETIME DEAL PROMO</strong>
              </p>
              <p className="m-0 mb-2">
                Due to popular demand, we are opening up{' '}
                <span style={{color: '#a801ff', fontWeight: 500}}>500 seats</span> to our legendary lifetime deal for
                48H only.
              </p>
            </>
          ) : annualPromo ? (
            <>
              <p className="m-0 mb-2">
                SPECIAL OFFER - Save <span style={{color: '#a801ff', fontWeight: 500}}>60% OFF</span> our annual plans
                for a limited time only.
              </p>
            </>
          ) : (
            ''
          )
        }
        noPaddingBottom
        titleSmall
        className="pricing-page"
        // subtitle2="Ahead of the launch, we would like to invite you today to be one of the very first to get access to Hexomatic and secure a very special early bird pre-launch lifetime deal.
        // Because this is our most ambitious idea to date, we want to soft launch first with a small cohort of our own paid customers first before opening the doors to the public later in the year.
        // This will enable us to prioritize our last mile development based on your business needs, feedback and suggestions."
        // subtitle3={
        //   <p>So here is the plan: We are opening <strong>3000 pre-launch seats</strong> for existing paid customers only. As usual, we always offer our best pricing to our own customers first so this is your chance to secure our legendary pre-launch lifetime deal at a fraction of the cost.</p>
        // }
        // subtitle4={
        //   <p>The offer ends on <strong>Thursday the 22nd at midnight</strong> (or when we reach the 3000 seats allocation). Pre-launch customers will then get first access to Hexomatic on the May 22nd with a public launch planned later this year at a higher price point.</p>
        // }
        // subtitle5='Your purchase will be covered by a 30 day refund period starting from the date of the soft launch planned on the May 22nd.'
        countdown={showLtdText || annualPromo}
        leftSeats={leftSeats}
      />

      <Section className="pricing-section">
        <div className={`pricing-section mx-auto ${annualPromo ? '' : 'mt-4'}`}>
          {!ltd && (
            <div className="d-flex pricing-section-header justify-content-center">
              <div className="d-inline-block position-relative wrap-el">
                <Switch
                  text1="Monthly"
                  text2="Annually"
                  active={!annual}
                  onChange={(val: string) => setAnnual(val === 'Annually')}
                />
                {showLtdText || annualPromo ? null : (
                  <div className="d-flex align-items-center justify-content-center get-month position-absolute">
                    {twoMonthsFreeIcon}
                  </div>
                )}
              </div>
            </div>
          )}
          <PricingPackeges
            interval={ltd ? 'ltd' : annual ? 'annual' : 'monthly'}
            products={pageContext.products}
            promo={promo}
            promoPage={showLtdText}
            // goldSpecial
            setAnnual={(val: boolean) => {
              setAnnual(true);
              // setAnnualPromo(val);
            }}
          />
          {/* <PricingFooter /> */}
          {/* <p className="text-center mt-4" style={{fontSize: '14px', color: '#050038', fontWeight: 600}}>
            All purchases are covered by our 30 day no questions asked refund policy.
          </p> */}
        </div>
      </Section>
      <PremiumCredits />
      <TrustedSection />
    </Layout>
  );
};

export default Pricing;
